import React, { createContext, useState } from 'react';

export const ContentContext = createContext();

export const ContentProvider = ({ children }) => {
  const [content, setContent] = useState('');
  const [selectedTextData, setSelectedTextData] = useState(null);

  // ... autres fonctions liées au contenu

  return (
    <ContentContext.Provider value={{ content, setContent, selectedTextData, setSelectedTextData }}>
      {children}
    </ContentContext.Provider>
  );
};

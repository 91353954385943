import React, { createContext, useState } from 'react';

export const UIContext = createContext();

export const UIProvider = ({ children }) => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // ... autres fonctions liées à l'interface utilisateur

  return (
    <UIContext.Provider value={{ isPopupVisible, setIsPopupVisible, isLoading, setIsLoading }}>
      {children}
    </UIContext.Provider>
  );
};

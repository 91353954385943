import React, { createContext, useState } from 'react';

export const AnalysisContext = createContext();

export const AnalysisProvider = ({ children }) => {
  const [articles, setArticles] = useState([]);

  const [analysisContent, setAnalysisContent] = useState({
    contentToAnalyse:{
        content: 'Collez l\'arrêt ici.',
        isFromDatabase: false,
        idFromDatabase: -1
    },
    contentAnalysed:{
        updatedContent: '',
        retrievedFromDatabase: '',
        detectedArticles: [
           
        ],
        contentFicheArret: ''
    },
    currentContent: 'Collez l\'arrêt ici.'
  });

        // content for each article
        //{
        //  title: '',
        //  content: '',
        //  linkLegifrance: '',
        //  contentWithoutHTML: '',
        //  code: '',
        //  isOpen: false
        //}

  const updateData = (section, sousSection, newValues) => {
    setAnalysisContent(prevData => ({
      ...prevData,
      [section]: {
        ...prevData[section],
        [sousSection]: {
          ...prevData[section][sousSection],
          ...newValues
        }
        }
        }))
    };

    const updateCurrentContent = (content) => {
        setAnalysisContent(prevData => ({
            ...prevData,
            currentContent: content

        }))     
    };

    const updateContentToAnalyse = (content, isFromDb=false, idInDb=-1) => {
        updateData('contentToAnalyse', 'content', content);
        updateData('contentToAnalyse', 'isFromDatabase', isFromDb);
        updateData('contentToAnalyse', 'idFromDatabase', idInDb);
        updateCurrentContent(content);
    };

    const updatedContentAnalysed = (updatedContent, retrievedFromDatabase, detectedArticles) => {
        updateData('contentAnalysed', 'updatedContent', updatedContent);
        updateData('contentAnalysed', 'retrievedFromDatabase', retrievedFromDatabase);
        updateData('contentAnalysed', 'detectedArticle', detectedArticles);
        updateCurrentContent(updatedContent);
    };

    const updateContentFicheArret = (contentFicheArret='') => {
        updateData('contentAnalysed', 'contentFicheArret', contentFicheArret);
    }

  return (
    <AnalysisContext.Provider value={{ articles, setArticles, analysisContent, updateData, updateContentToAnalyse, updatedContentAnalysed, updateCurrentContent }}>
  {children}
</AnalysisContext.Provider>

  );
};

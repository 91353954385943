import React, { useState } from 'react';
import Modal from './Modal'; // Ensure you have this Modal component ready

function ResultsList({ results, searchQuery }) {
  const [selectedResult, setSelectedResult] = useState(null);

  const insertLinks = (text, extractedElements) => {
    let result = text;
    // extractedElements.forEach((element) => {
    //   if (element.type === 'arret' || element.type === 'loi' || element.type === 'decret') {
    //     const linkText = `${element.type.charAt(0).toUpperCase() + element.type.slice(1)} du ${element.date}`;
    //     const link = `<a href="#" class="underline">${linkText}</a>`;
    //     result = result.substring(0, element.position_start) + link + result.substring(element.position_end);
    //   }
    // });
    return result;
  };
  const highlightKeywords = (content, query) => {
    if (!query) return content; // If the query is empty, return the original content
  
    console.log(query);
    const words = query.toLowerCase().split(/\s+/); // Split the query into words
    let snippet = '';
    let startIndex = 0;
  
    // Find the index of the first occurrence of a keyword
    words.some(word => {
      const index = content.toLowerCase().indexOf(word, startIndex);
      if (index !== -1) {
        startIndex = index;
        return true;
      }
    });
  
    // If no keyword is found, return an empty string
    if (startIndex === 0) return '';
    
    const len_content = 200;
    // Get up to 50 characters before the first occurrence of a keyword
    const endIndex = Math.min(startIndex + len_content, content.length);
    snippet = content.substring(Math.max(0, startIndex - len_content), endIndex);
  
    // Highlight the keywords in the snippet
    words.forEach(word => {
      const regex = new RegExp(`\\b${word}\\b`, 'gi'); // Word boundary regex to match whole words only
      snippet = snippet.replace(regex, (match) => `<span class="bg-yellow-200">${match}</span>`);
    });
  
    // Add ellipsis if the snippet was trimmed
    snippet = (startIndex > len_content ? '...' : '') + snippet + (endIndex < content.length ? '...' : '');
  
    return snippet;
  };
    

  const handleResultClick = (result) => {
    const markedUpText = insertLinks(result.texte, result.extracted);
    setSelectedResult({ ...result, markedUpText });
  };

  const handleCloseModal = () => {
    setSelectedResult(null);
  };

  return (
    <>
      <ul id="resultsList" className="space-y-4">
        {results.map((item) => {
            const result = item.result; // Adjusted for nested result
            if (result.type === 'arret') {
                result.title_search_display = result.titre;
            } else {
                result.title_search_display = result.code + ' - ' + result.titre;
            }
            const hasSource = result._source && result._source.Numero_Dossier && result._source.Nom_Juridiction;
            const Numero_Dossier = hasSource ? result._source.Numero_Dossier : 'N/A';
            const Date_Audience = hasSource ? result._source.Date_Audience : 'N/A';
            const Date_Lecture = hasSource ? result._source.Date_Lecture : 'N/A';
            const Date_Mise_Jour = hasSource ? result._source.Date_Mise_Jour : 'N/A';
            const Type_Decision = hasSource ? result._source.Type_Decision : 'N/A';
            const Texte_Integral = hasSource ? result._source.Texte_Integral : 'N/A';
            const t_Requerant = hasSource ? result._source.t_Requerant : 'N/A';
            const Code_Juridiction = hasSource ? result._source.Code_Juridiction : 'N/A';
            const Code_Publication = hasSource ? result._source.Code_Publication : 'N/A';
            const Formation_Jugement = hasSource ? result._source.Formation_Jugement : 'N/A';
            const Identification = hasSource ? result._source.Identification : 'N/A';
            const Numero_Role = hasSource ? result._source.Numero_Role : 'N/A';
            const Solution = hasSource ? result._source.Solution : 'N/A';
            const Type_Rec = hasSource ? result._source.Type_Rec : 'N/A';
            const dateAudience = hasSource ? result._source.Date_Audience : 'N/A';
            const dateLecture = hasSource ? result._source.Date_Lecture : 'N/A';
            const dateMiseAJour = hasSource ? result._source.Date_Mise_Jour : 'N/A';
            const typeDecision = hasSource ? result._source.Type_Decision : 'N/A';
            const texteIntegral = hasSource ? result._source.Texte_Integral : 'N/A';
            const tRequerant = hasSource ? result._source.t_Requerant : 'N/A';
            const codeJuridiction = hasSource ? result._source.Code_Juridiction : 'N/A';
            const codePublication = hasSource ? result._source.Code_Publication : 'N/A';
            const formationJugement = hasSource ? result._source.Formation_Jugement : 'N/A';
            const identification = hasSource ? result._source.Identification : 'N/A';
            const numeroRole = hasSource ? result._source.Numero_Role : 'N/A';
            const solution = hasSource ? result._source.Solution : 'N/A';
            const typeRec = hasSource ? result._source.Type_Rec : 'N/A';
    
          return (
            <li key={result._id} className="bg-white shadow overflow-hidden px-4 py-4 sm:px-6 sm:rounded-lg mb-4 cursor-pointer" onClick={() => handleResultClick(result)}>

                <div className="py-4 cursor-pointer" onClick={() => handleResultClick(result)}>
                    {/* if the type is "article code" : display the result.code, otherwise juste the title */
                    }

                  <h3 className="text-xl border-b border-gray-200 pb-2">
                  {result.title_search_display}</h3>
                  <p className="text-gray-500 my-4">Score: {result.score || 'N/A'}</p>
                  <div className="text-sm mb-4" dangerouslySetInnerHTML={{ __html: highlightKeywords(result.texte, searchQuery, 100) }} />


                </div>
              </li>
          );
        })}
      </ul>
      {selectedResult && <Modal details={{
        title: `${selectedResult.titre}`,
        code: selectedResult.code,
        description: `Score: ${selectedResult.score}`,
        texte: selectedResult.texte,
        texte_html: selectedResult.texte_html,
        id: selectedResult.id,
        type : selectedResult.type,
      }} onClose={handleCloseModal} />}
    </>
  );
}

export default ResultsList;

import React, { useState } from 'react';

function SearchInput({ onSearch }) {
  const [query, setQuery] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const initiateSearch = (searchQuery) => {
    setIsLoading(true);
    // Wrap onSearch in a Promise
    Promise.resolve(onSearch(searchQuery))
      .finally(() => {
        setIsLoading(false); // Always stop loading, regardless of onSearch result
      });
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      initiateSearch(query);
    }
  };

  return (
    <div className="mb-8 w-6/12">
      <div className="mt-2 relative rounded-md shadow-sm">
        <input
          type="text"
          name="search"
          className="block p-2 pl-7 w-full pr-12 sm:text-sm border-gray-300 rounded-md"
          placeholder="Search..."
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          onKeyPress={handleKeyPress}
        />
        <button
          className="absolute inset-y-0 right-0 flex items-center p-2 bg-white text-zinc-500 rounded-r-md"
          onClick={() => initiateSearch(query)}
          disabled={isLoading}
        >
          {isLoading ? (
            <span>Loading...</span>
          ) : (
            <span className="material-icons">search</span>
          )}
        </button>
      </div>
    </div>
  );
}

export default SearchInput;

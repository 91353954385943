import React, { useState } from 'react';
import Filters from './searchElements/Filters.js';
import ResultsList from './searchElements/ResultsList.js';
import SearchInput from './searchElements/SearchInput.js';
function SearchComponent() {
    const [searchResults, setSearchResults] = useState([]);
    const [query, setQuery] = useState('');
    const [searchInfo, setSearchInfo] = useState({ resultsCount: 0, searchTime: 0 }); // New state for search info

    const fetchSearchResults = async (query) => {
      const startTime = performance.now(); // Start timing the search

      try {
        const response = await fetch('https://api.legifinder.fr/search', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ query })
        });

        const endTime = performance.now(); // End timing the search

        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }
        const data = await response.json();

        if (data.results && data.results.length > 0) {
          const formattedResults = data.results.map(result => ({
            result,
          }));
          setSearchResults(formattedResults);
          setSearchInfo({
            resultsCount: data.results.length,
            searchTime: (endTime - startTime).toFixed(2) // Calculate and format the search time
          });
        } else {
          setSearchResults([]);
          setSearchInfo({ resultsCount: 0, searchTime: (endTime - startTime).toFixed(2) });
        }
      } catch (error) {
        console.error("Failed to fetch search results:", error);
        setSearchInfo({ ...searchInfo, searchTime: (performance.now() - startTime).toFixed(2) }); // Update searchTime in case of error
      }
    };

    return (
        <div className="bg-gray-100 w-full px-4 py-8">
          <SearchInput onSearch={(q) => { setQuery(q); fetchSearchResults(q); }} />
          {/* <Filters /> */}
          <div className="mt-4">
            <p>Found {searchInfo.resultsCount} results in {searchInfo.searchTime} milliseconds</p>
          </div>
          <ResultsList results={searchResults} searchQuery={query} />
        </div>
    );
}

export default SearchComponent;

import React, { useEffect, useRef, useState } from 'react';
import io from 'socket.io-client';

function display_arret({ details, onClose, summary, showSummarizeButton, handleSummarizeClick }, ref) {
    return (
        <div className="relative top-20 mx-auto p-5 border w-11/12 shadow-lg rounded-md bg-white" ref={ref}>
            <div className="mt-3 text-left px-7 py-3">
                <h3 className="leading-6 font-medium text-gray-900 text-2xl" id="modal-title">{details.title}</h3>
                <br></br>
                <hr />
                <div className="text-left mt-5">
                    <h1 className="text-xl font-bold">Résumé de l'arrêt</h1>
                    {/* Automatically display the summary once it's available */}
                    <div className="text-gray-500 w-full mt-2">{summary}</div>
                </div>

        <hr className="my-4" />
        <div className="text-left">
          <h1 className="text-xl font-bold mb-5">Texte intégral</h1>
          {/* Make sure to sanitize the HTML content if it's coming from an untrusted source */}
          <div className="text-sm mb-4" dangerouslySetInnerHTML={{ __html: details.texte_html }} />
        </div>
      </div >
      </div >
    );
}

function display_article_code({ onClose, details }, modalContentRef) {

    return (

        <div className="relative top-20 mx-auto p-5 border w-11/12 shadow-lg rounded-md bg-white" ref={modalContentRef}>
            <div className="flex w-full">
                {/* <div onClick={onClose} className=" justify-self-start  align-middle"><span className="material-icons mr-10">arrow_back</span>Retour aux résultats</div> */}
            </div>
            <div className="mt-3 text-left px-7 py-3">
                <h3 className=" leading-6 font-medium text-gray-900 text-2xl" id="modal-title">{details.title}</h3>
                {/* <br></br>
                <br></br>
                <hr></hr> */}
                <div className="mt-2 ">
                    {/* <p className=" ">{details.description}</p>
            <h1 className="text-xl font-bold">Synthèse</h1>
        <p className=" "><strong>Date d'audience: </strong>{details.dateAudience}</p>
        <p className=" "><strong>Date de décision: </strong>{details.dateLecture}</p>
        <p className=" "><strong>Date de mise à jour: </strong>{details.dateMiseAJour}</p>
        <p className=" "><strong>Type de décision: </strong>{details.typeDecision}</p>
        <p className=" "><strong>Requérant: </strong>{details.tRequerant}</p>
        <p className=" "><strong>Code de juridiction: </strong>{details.codeJuridiction}</p>
        <p className=" "><strong>Code de publication: </strong>{details.codePublication}</p>
        <p className=" "><strong>Formation de jugement: </strong>{details.formationJugement}</p>
        <p className=" ">Identification: {details.identification}</p>
        <p className=" "><strong>Numéro de rôle: </strong>{details.numeroRole}</p> 
        <p className=" "><strong>Solution: </strong>{details.solution}</p>
        <p className=" "><strong>Type de recours: </strong> {details.typeRec}</p>
        <br></br>
        <br></br> */}

                    {/* {showSummarizeButton && (
            <button onClick={handleSummarizeClick} className="bg-gray-500 text-white p-2 rounded-lg ">Charger le résumé</button>
          )} */}
                    <br />
                    <hr /><br />
                    <div className="text-left">
                        <h1 className="text-xl font-bold mb-5">Texte intégral</h1>

                        <div className="text-sm mb-4" dangerouslySetInnerHTML={{ __html: details.texte_html }} />
                        {/* {extracted && (
    <div>
      <h4>Éléments extraits :</h4>
      <ul>
        {extracted.map((item) => (
          <li key={`${item.type}-${item.date}`}>
            {item.type.charAt(0).toUpperCase() + item.type.slice(1)} du {item.date}
          </li>
        ))}
      </ul>
    </div>
        )} */}
                    </div>
                </div>

            </div>
        </div>
    );
}

// Use React.forwardRef for forwarding refs to your functional components if needed
const DisplayArretWithRef = React.forwardRef(display_arret);
const DisplayArticleCodeWithRef = React.forwardRef(display_article_code);


function Modal({ details, onClose }) {
    const modalContentRef = useRef(null);
    const [summary, setSummary] = useState('');
    const [socket, setSocket] = useState(null);
    // State to control the visibility of the summarize button
    const [showSummarizeButton, setShowSummarizeButton] = useState(true);

    const { markedUpText, extracted } = details;
    // console.log(details);
    useEffect(() => {
        const newSocket = io(`https://api.legifinder.fr/`, {
            transports: ['websocket'],
            path: '/socket.io' // specify the path here if it's different from the default '/socket.io'
          });
        setSummary('');
        setSocket(newSocket);
      
        newSocket.on('connect', () => {
          console.log("I'm connected to the server.");
        });
      
        newSocket.on('connect_error', (data) => {
          console.log("The connection failed!", data);
        });
      
        newSocket.on('disconnect', () => {
          console.log("I'm disconnected from the server.");
        });
      
        newSocket.on('new_message', (data) => {
          console.log("Received 'new_message' event with data: ", data);
          setSummary((prevSummary) => `${prevSummary}${data.message}`);
        });
      
        return () => newSocket.close();
      }, []);
    useEffect(() => {
        if (details && details.texte && socket) {
            socket.emit('submit', { document_id: details.id });
        }
    }, [details, socket]);

    const insertLinks = (text, extractedElements) => {
        let result = text;
        extractedElements.forEach((element) => {
            if (element.type === 'arret' || element.type === 'loi' || element.type === 'decret') {
                const linkText = `${element.type.charAt(0).toUpperCase() + element.type.slice(1)} du ${element.date}`;
                const link = `<a href="#" class="underline">${linkText}</a>`;
                result = result.substring(0, element.position_start) + link + result.substring(element.position_end);
            }
        });
        return result;
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalContentRef.current && !modalContentRef.current.contains(event.target)) {
                onClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onClose]);

    const handleSummarizeClick = () => {
        if (details && details.texte && socket) {
            console.log("Emitting 'submit' event with data: ", { document_id: details.id });
            socket.emit('submit', { document_id: details.id });
            //   setSummary('');
            setShowSummarizeButton(false); // Hide the button after it's clicked
        } else {
            console.log("Details, details.texte, or socket is undefined. Cannot emit 'submit'.");
        }
    };

    const renderSubModal = () => {
        switch (details.type) {
            case 'arret':
                return (
                    <DisplayArretWithRef
                        details={details}
                        onClose={onClose}
                        ref={modalContentRef}
                        summary={summary}
                        showSummarizeButton={showSummarizeButton}
                        handleSummarizeClick={handleSummarizeClick}
                    />
                );

            default:
                return <DisplayArticleCodeWithRef details={details} onClose={onClose} ref={modalContentRef} />;
        }
    };


    //   if (!details) return null;
    //   const formattedDetailedText = details.detailedText.split('\n').map((line, index, array) => (
    //     <span key={index}>{line}{index !== array.length - 1 && <br />}</span>
    //   ));

    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" id="my-modal">
            {renderSubModal()}
        </div>
    );
}

export default Modal;


function InstructionPopup({ onAcknowledge }) {
    return (
        <div className="fixed top-0 left-0 w-full h-full bg-opacity-50 bg-black flex justify-center items-center z-90">
            <div className="bg-white p-4 rounded w-1/2">
                <div className="relative">
                    <button 
                        className="absolute top-1 right-1 hover:bg-gray-200 rounded-full p-4"
                        onClick={onAcknowledge}  // Close the popup when X is clicked
                    >
                        <span className="material-icons-outlined">
                            close
                        </span>
                    </button>
                    <div>
                        <p>Veuillez sélectionner le texte souhaité. Une fois votre sélection effectuée, une fenêtre popup apparaîtra pour d'autres actions.</p>
                    </div>
                </div>

                <button 
                    className="bg-gray-800 hover:bg-gray-700 text-white rounded shadow flex items-center justify-center w-36 h-10 mt-10"
                    onClick={onAcknowledge}
                >
                    Compris !
                </button>
            </div>
        </div>
    );
}

export default InstructionPopup;
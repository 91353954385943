// src/components/TextAreaComponent.jsx
import React, { useState, useRef, useEffect, useContext } from 'react';
import { useCallback } from 'react';  // new import
import {fetchUserToken} from '../services/getAuthToken.js';
import SelectTextPopup from './SelectTextPopup';
import SendTextPopup from './SendTextPopup';
import InstructionPopup from './InstructionPopup';

import { UserContext } from '../contexts/UserContext';
import { UIContext } from '../contexts/UIContext';
import { ContentContext } from '../contexts/ContentContext';
import { AnalysisContext } from '../contexts/AnalysisContext';

import { Auth } from '@aws-amplify/auth';

async function getCurrentUser() {
    try {
        const currentUser = await Auth.currentAuthenticatedUser();
        return {
            email: currentUser.attributes.email,
            userId: currentUser.attributes.sub
        };
    } catch (error) {
        console.error("Error fetching Cognito user:", error);
        throw error;
    }
}

function TextAreaComponent({ onNewArticles, toggleItem, onSignalerClick, isPopupVisible, onClosePopup, content: initialContent, triggerAnalysis, resetTriggerAnalysis }) {
    // CONTEXT ELEMENTS
    const { analysisContent, updateData, updateContentToAnalyse, updatedContentAnalysed, updateCurrentContent } = useContext(AnalysisContext);

    const contentRef = useRef(localStorage.getItem('editableContent') || 'Collez l\'arrêt ici.');
    const editableDivRef = useRef(null);

    const [isLoading, setIsLoading] = useState(false);
    const [hasBeenFocused, setHasBeenFocused] = useState(false);
    const [openArticle, setOpenArticle] = useState(null);
    const [isSignalerActivated, setIsSignalerActivated] = useState(false);

    const [selectedTextData, setSelectedTextData] = useState(null);
    const [isSendTextPopupVisible, setIsSendTextPopupVisible] = useState(false);

    const [isInstructionPopupVisible, setIsInstructionPopupVisible] = useState(false);
    const showInstructionAndEnableSelection = () => {
        setIsInstructionPopupVisible(true);
    };

    const handleInstructionAcknowledge = () => {
        setIsInstructionPopupVisible(false);
        setIsSignalerActivated(true);
    };
    
    const handleFocus = () => {
        if (!hasBeenFocused) {
            if (analysisContent.currentContent == 'Collez l\'arrêt ici.')
            {
                updateContentToAnalyse('');
            }
            setHasBeenFocused(true)
            editableDivRef.current.removeEventListener('focus', handleFocus);
        }
    };

    const updateContent = useCallback((newContent) => {
        contentRef.current = newContent;
        localStorage.setItem('editableContent', newContent);
    }, []);

    const handleInput = useCallback((e) => {
        updateContent(e.currentTarget.innerHTML);
    }, [updateContent]);

    
    const colorClasses = {
        blue: {
            bgColor: 'bg-blue-100',
            textColor: 'text-blue-800',
            darkBgColor: 'dark:bg-blue-900',
            darkTextColor: 'dark:text-blue-300',
        },
        gray: {
            bgColor: 'bg-gray-100',
            textColor: 'text-gray-800',
            darkBgColor: 'dark:bg-gray-900',
            darkTextColor: 'dark:text-gray-300',
        },
        red: {
            bgColor: 'bg-red-100',
            textColor: 'text-red-800',
            darkBgColor: 'dark:bg-red-900',
            darkTextColor: 'dark:text-red-300',
        },
        green: {
            bgColor: 'bg-green-100',
            textColor: 'text-green-800',
            darkBgColor: 'dark:bg-green-900',
            darkTextColor: 'dark:text-green-300',
        },
        yellow: {
            bgColor: 'bg-yellow-100',
            textColor: 'text-yellow-800',
            darkBgColor: 'dark:bg-yellow-900',
            darkTextColor: 'dark:text-yellow-300',
        },
        purple: {
            bgColor: 'bg-purple-100',
            textColor: 'text-purple-800',
            darkBgColor: 'dark:bg-purple-900',
            darkTextColor: 'dark:text-purple-300',
        },
    };

    // Helper function to get color class
    const getColorClass = (code) => {
        const colors = ['blue', 'gray', 'red', 'green', 'yellow', 'purple'];
        const hash = [...code].reduce((hashAccumulator, char) => char.charCodeAt(0) + ((hashAccumulator << 5) - hashAccumulator), 0);
        return colorClasses[colors[Math.abs(hash) % colors.length]];
    };

    const handleArticleClick = useCallback((articleId) => {
        console.log(articleId);
        toggleItem(articleId);  // use toggleItem here
    }, [toggleItem]);   // Adjusted here, otherwise, keep it as [setOpenItem]

    const assignColorToArticles = (articles) => {
        return articles.map(article => ({
            ...article,
            colorClass: getColorClass(article.code)
        }));
    };

    useEffect(() => {
        if (!hasBeenFocused) {
            editableDivRef.current.addEventListener('focus', handleFocus);
        }else {
            editableDivRef.current.removeEventListener('focus', handleFocus); 
        }
        // Make handleArticleClick globally accessible
        window.handleArticleClick = handleArticleClick;

        // Clean up when component unmounts
        return () => {
            delete window.handleArticleClick;
        };
    }, [handleArticleClick]);

    const handleAnalyzeClick = async () => {
        setIsLoading(true);
        // Updated line below:
        const userToken = await fetchUserToken();
        const userInfo = await getCurrentUser();
        try {
            const response = await fetch('https://nli4lk79ya.execute-api.us-east-1.amazonaws.com/legisAnalyse', {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ text: analysisContent.currentContent, user: userInfo }),
            });
            const json = await response.json();
            json.articles.sort((a, b) => b.debut - a.debut); // Sort articles
            let articlesWithColor = assignColorToArticles(json.articles);
            const articlesWithIDs = articlesWithColor.map((article, index) => ({
                ...article,
                id: `${index}`  // Create a unique id based on debut property and index
            }));
            let currentContent = editableDivRef.current.innerHTML;
            if (currentContent === json.plainText) {
                let itemId = 0;
                for (let article of articlesWithIDs) {
                    itemId++;
                    let startPos = article.debut;
                    let endPos = article.fin;
                    const colorClass = article.colorClass;  // The colorClass is now directly available from article object
                    let buttonHTML = `
                        <button 
                            class="displayTextButton ${colorClass.bgColor} ${colorClass.textColor} text-xs font-medium mr-2 px-2.5 py-0.5 rounded ${colorClass.darkBgColor} ${colorClass.darkTextColor}" 
                            articleName="${article.name}" 
                            data-item-id="${itemId}" 
                            onClick="window.handleArticleClick('${article.id}')"
                        >
                            ${currentContent.slice(startPos, endPos)}
                        </button>`;


                    currentContent = currentContent.slice(0, startPos) + buttonHTML + currentContent.slice(endPos);
                }
                updatedContentAnalysed(currentContent);  // Update the content with buttons
                articlesWithIDs.sort((a, b) => a.debut - b.debut);  // Sort articles back to ascending order based on the debut
                onNewArticles(articlesWithIDs); // pass the new articles to App
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setIsLoading(false);
        }
        window.handleArticleClick = handleArticleClick;
    };

    const clearContent = () => {
        updateCurrentContent('');  // Clear the content
    };
    
    const handleSignalerClick = () => {
        setIsSignalerActivated(true);
    };
    
    const handleTextSelection = useCallback(() => {
        if (isSignalerActivated) {
            const selectedText = window.getSelection().toString();
            if (selectedText) {
                setSelectedTextData(selectedText);
                setIsSendTextPopupVisible(true);
                setIsSignalerActivated(false);  // reset to false after text has been selected
            }
        }
    }, [isSignalerActivated]);

    
    const handleSend = (selectedText, additionalInfo) => {
        // Call the API with the selectedText and additionalInfo
        // For now, we'll just console.log the values:
        console.log('Selected Text:', selectedText);
        console.log('Additional Info:', additionalInfo);
    
        // Close the SendTextPopup:
        setIsSendTextPopupVisible(false);
        setSelectedTextData(null);  // Clear the selected text data
    };

    
    useEffect(() => {
        if (isSignalerActivated && !isSendTextPopupVisible) {
            editableDivRef.current.addEventListener('mouseup', handleTextSelection);
        }
        return () => {
            editableDivRef.current.removeEventListener('mouseup', handleTextSelection);
        };
    }, [handleTextSelection, isSendTextPopupVisible, isSignalerActivated]);
    
    useEffect(() => {
        if (analysisContent.currentContent) {
            updatedContentAnalysed(analysisContent.currentContent);
        }
    }, [analysisContent.currentContent]);

    useEffect(() => {
        if (triggerAnalysis) {
            handleAnalyzeClick();
            resetTriggerAnalysis();
        }
    }, [triggerAnalysis]);
    
    
    
    useEffect(() => {
        const editableDiv = editableDivRef.current;
    
        if (isSignalerActivated && !isSendTextPopupVisible) {
            editableDiv.addEventListener('mouseup', handleTextSelection);
    
            // Cleanup function
            return () => {
                if (editableDiv) {
                    editableDiv.removeEventListener('mouseup', handleTextSelection);
                }
            };
        }
    }, [handleTextSelection, isSendTextPopupVisible, isSignalerActivated]);
    
    useEffect(() => {
        updateContentToAnalyse(initialContent);
    }, [initialContent]);
    
    const handleEnvoyerTextClick = () => {
        setIsSendTextPopupVisible(true);
    };

    useEffect(() => {
        const editableDiv = editableDivRef.current;
        if (editableDiv) {
            editableDiv.innerHTML = contentRef.current; // Set initial content
            editableDiv.addEventListener('input', handleInput); // Handle input changes

            // Cleanup function
            return () => {
                editableDiv.removeEventListener('input', handleInput);
            };
        }
    }, [handleInput]);

    return (
        <div className="w-1/2 mt-28 fixed pr-5 flex flex-col" style={{height: '90vh'}}>
    <div className="relative w-full">
        <div
            className="border left-3 w-1/2 h-4/5 border-gray-300 p-5 bg-white shadow rounded overflow-auto fixed" 
            contentEditable="true"
            ref={editableDivRef}
            style={{ flex: '1'}}  
            dangerouslySetInnerHTML={{ __html: analysisContent.currentContent }}
        />
        <button 
            onClick={clearContent} 
            className="absolute top-7 right-0 bg-white pt-1 pl-1 pr-1 rounded-lg shadow"
        >
            <span class="material-icons-outlined">
                close
            </span>
        </button>
    </div>
            <div className="mt-4 w-1/2 bg-slate-100 bottom-3 left-3 fixed flex justify-between items-center flex-shrink-0">  
                <button className="shadow rounded-full p-4 overflow-auto text-grey flex items-center" onClick={showInstructionAndEnableSelection}>
                    Signaler un oubli
                    <span className="material-symbols-outlined mt-1">
                        flag
                    </span>
                </button>
        <button
            onClick={handleAnalyzeClick}
            className="bg-gray-800 hover:bg-gray-700 text-white rounded shadow flex items-center justify-center w-36 h-12"
            disabled={isLoading}
        >
            {isLoading ? (
               <span class="material-symbols-outlined text-white animate-spin">
                 progress_activity
               </span> 
            ) : (
                "Analyser le texte "
            )}
        </button>
        
    </div>
        {isPopupVisible && <SelectTextPopup onClose={onClosePopup} onValidation={handleSignalerClick} />}

        {isInstructionPopupVisible && <InstructionPopup onAcknowledge={handleInstructionAcknowledge} />}

        {isSendTextPopupVisible && <SendTextPopup selectedText={selectedTextData} onSend={handleSend} />}

        {isSendTextPopupVisible && <SendTextPopup selectedText={selectedTextData} onSend={handleSend} />}
</div>
    


    );
}

export default TextAreaComponent;

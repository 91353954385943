// App.js
import React, { useState } from 'react';
import './styles/tailwind.css';
import TextAreaComponent from './components/TextArea.js';
import ParentComponent from './components/ParentComponent.js';
import TopBar from './components/TopBar.js';
import { Amplify } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import awsExports from './aws-exports';
import './App.css'
import { I18n } from 'aws-amplify';
import { translations } from '@aws-amplify/ui-react';
import { UserProvider } from './contexts/UserContext';
import { UIProvider } from './contexts/UIContext';
import { ContentProvider } from './contexts/ContentContext';
import { AnalysisProvider } from './contexts/AnalysisContext';
import SearchComponent from './components/SearchComponent.js';
import { Search } from '@mui/icons-material';

I18n.putVocabularies(translations);
I18n.setLanguage('fr');
I18n.putVocabularies({
    fr: {
        'Sign In': 'Se connecter',
        'Sign Up': "S'inscrire",
    },
    es: {
        'Sign In': 'Registrarse',
        'Sign Up': 'Regístrate',
    },
});


Amplify.configure(awsExports);
export default function App() {
    const [articles, setArticles] = useState([]);
    const [openItem, setOpenItem] = useState(null);

    // Add the following state:
    const [isPopupVisible, setIsPopupVisible] = useState(false);

    const handleSignalerClick = () => {
        setIsPopupVisible(true);
    };

    const handleClosePopup = () => {
        setIsPopupVisible(false);
    };

    const toggleItem = (id) => {
        if (id === openItem) {
            // if the article is already open, close it by setting openItem to null
            setOpenItem(null);
        } else {
            // otherwise, open the article by setting openItem to the article.id
            setOpenItem(id);
        }
    };

    const components = {
        Header() {
            return (
                <div className="bg-navy text-white px-6 py-4 flex justify-between items-center">
                    <h1 className="text-3xl">Legifinder</h1>
                    <div className="flex items-center">
                        {/* <span className="text-lg font-roboto mr-4">Hello, {user.username}</span> */}
                    </div>
                </div>
            );
        },
    }

    const handleNewArticles = (newArticles) => {
        setArticles(newArticles);
    };

    const [textAreaContent, setTextAreaContent] = useState('');
    const [triggerAnalysis, setTriggerAnalysis] = useState(false);

    const updateTextAreaContent = (content) => {
        setTextAreaContent(content);
        setTriggerAnalysis(true);
    };

    return (
        <div>
            <Authenticator signUpAttributes={["preferred_username", "email"]} components={components}>
                {({ signOut, user }) => (
                    <>
                        {/* {console.log(user)}
                      {user && user.attributes && console.log(user.attributes)} */}
                          <UserProvider>
                        <UIProvider>
        <ContentProvider>
          <AnalysisProvider>
                            <main>
                                <TopBar user={user} onSignOut={signOut}
                                    updateTextAreaContent={updateTextAreaContent} />
                                 <div className="container flex flex-col">
                                    <div className=" flex mt-16">
                                        <SearchComponent></SearchComponent>
                                        {/* <ParentComponent articles={articles} toggleItem={toggleItem} openItem={openItem} /> */}
                                        {/* <TextAreaComponent
                                            onNewArticles={handleNewArticles}
                                            toggleItem={toggleItem}
                                            onSignalerClick={handleSignalerClick}
                                            isPopupVisible={isPopupVisible}
                                            onClosePopup={handleClosePopup}
                                            content={textAreaContent}
                                            triggerAnalysis={triggerAnalysis}
                                            resetTriggerAnalysis={() => setTriggerAnalysis(false)}
                                        /> */}
                                        
                                    </div>
                                </div>
                           </main>
                            </AnalysisProvider>
        </ContentProvider>
      </UIProvider>
    </UserProvider>
                    </>
                )}

            </Authenticator>
        </div>
    );
}  
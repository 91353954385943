import React, { useState, useEffect } from 'react';
import axios from 'axios'; // You'll need to install axios if you haven't: `npm install axios`
import '../styles/tailwind.css';

const TopBar = ({ user, onSignOut, updateTextAreaContent }) => {
    const [searchActive, setSearchActive] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [timeoutId, setTimeoutId] = useState(null);
  
    const formatSnippet = (content, query) => {
        const index = content.toLowerCase().indexOf(query.toLowerCase());
        if (index === -1) return ''; // If the query is not found
    
        const start = Math.max(0, index - 50);
        const end = Math.min(content.length, index + query.length + 50);
    
        let snippet = content.substring(start, end);
        const regex = new RegExp(query, 'gi'); // Dynamic regex based on query
        snippet = snippet.replace(regex, (match) => `<span class="bg-yellow-200">${match}</span>`);
        return snippet;
    };
    
    // Function to handle search input changes
    const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);  // Set the search query state
    
    if (event.key === 'Enter') {
      fetchSearchResults(event.target.value);
    }
  };
  
  
    const fetchSearchResults = async (query) => {
        try {

          const response = await axios.get('https://nli4lk79ya.execute-api.us-east-1.amazonaws.com/feedback/search', {
        //   const response = await axios.get('http://127.0.0.1:5000/search', {
            params: { search: query },
            headers: {
              'Content-Type': 'application/json'
            }
          });
          console.log("API response:", response);
      
          const updatedResults = response.data.map(result => {
            console.log("Processing result:", result);
            const snippet = formatSnippet(result.contenu, query);
            console.log("Snippet for this result:", snippet);
            return {
              ...result,
              snippet: snippet
            };
          });
      
          console.log("updatedResults:", updatedResults);
          setSearchResults(updatedResults);
        } catch (error) {
          console.error("Error fetching search results:", error);
        }
      };
    
    // Use useEffect to trigger the API request on search query change
    useEffect(() => {
      if (searchQuery) {
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
        const newTimeoutId = setTimeout(() => {
          fetchSearchResults(searchQuery);
        }, 10);
        setTimeoutId(newTimeoutId);
      }
    }, [searchQuery]);

  // Function to copy content to clipboard
  const copyContentToClipboard = (content) => {
    navigator.clipboard.writeText(content).then(() => {
      console.log('Content copied to clipboard');
    }).catch(err => {
      console.log('Failed to copy content: ', err);
    });
  };
  
  function ExtractOrginTribunal(text) {
    // Cette regex correspond à tout texte jusqu'à la deuxième virgule
    const regex = /^(.*?,.*?),/;
    const matches = text.match(regex);
  
    // Si le texte correspond à l'expression régulière, retournez le premier groupe capturé
    if (matches && matches[1]) {
      return matches[1];
    }
  
    // Sinon, retournez le texte original ou une chaîne vide
    return text || '';
  }
  function extractNumber(text) {
    // Cette regex cherche une chaîne de plus de 5 caractères en majuscules
    // et contenant au moins un chiffre.
    const regex = /\b[A-Z]*[0-9][A-Z0-9]{4,}\b/;
    const matches = text.match(regex);
  
    // Si un numéro correspondant est trouvé, il sera retourné
    if (matches) {
      return matches[0];
    }
  
    // Sinon, retournez null ou une chaîne vide
    return null;
  }

  const UPDATE_FUNCTION_FOR_TEXTAREA = (text) => {
    if (updateTextAreaContent && typeof updateTextAreaContent === 'function') {
        const formattedText = text.replace(/\n/g, "<br>");
        updateTextAreaContent(formattedText);
        toggleSearch();

    } else {
      console.error('updateTextAreaContent is not available or not a function');
    }
  };
  function extractDate(text) {
    // Cette regex correspond à une date au format JJ/MM/AAAA
    const regex = /(\d{2}\/\d{2}\/\d{4})/;
    const matches = text.match(regex);
  
    // Si une date est trouvée, elle sera retournée
    if (matches) {
      return matches[0];
    }
  
    // Sinon, retournez null ou une chaîne vide
    return null;
  }

  // Toggle search bar visibility
  const toggleSearch = () => setSearchActive(!searchActive);

  return (
    <div className="fixed text-black px-6 py-4 flex justify-between items-center w-full z-10 border-b-2 border-gray-300 bg-white">
          <div className="flex justify-between items-center">
        <h1 className="text-3xl pr-4">Legifinder</h1>
        {/* <span className="mt-1 material-symbols-outlined cursor-pointer" onClick={toggleSearch}>
          search
        </span> */}
        
        
        {/* {searchActive && (
      <div className="relative">
        <input
          type="text"
          placeholder="Rechercher"
          className="ml-3 px-4 py-2 rounded bg-white text-navy transition-all duration-300 ease-in-out focus:outline-none"
          value={searchQuery}
          onChange={handleSearchChange}
          autoFocus
        />
            <div className="fixed bg-white text-navy mt-2 p-4 rounded shadow-lg overflow-y-auto w-3/4 h-4/5">
          {searchResults.map(result => (
            <div key={result.id} className="hover:bg-gray-100 p-2 rounded transition-all duration-300 ease-in-out">
                  <div className="flex justify-between items-center">
                    <h3 className="font-bold">{ExtractOrginTribunal(result.titre_principal)} </h3>
                    <p>N° {extractNumber(result.titre_principal)} - {extractDate(result.titre_principal)}</p>
                  </div>
                  <div className="text-sm mt-2" dangerouslySetInnerHTML={{ __html: result.snippet }}></div> 
                  <div className="mt-2 p-5 rounded indent-1 flex justify-between">
                    <a target="_blank" className="flex items-center" href={result.link}>
                      Voir sur legifrance 
                      <span className="material-symbols-outlined ml-2">
                        open_in_new
                      </span>
                    </a>
                    <button onClick={() => UPDATE_FUNCTION_FOR_TEXTAREA(
                        "<h2>" + ExtractOrginTribunal(result.titre_principal) + " - " + extractDate(result.titre_principal) + "</h2> Numéro de dossier : " + result.numero_affaire + "\n\n" + result.contenu)} className="flex items-center">
                      Analyser le contenu 
                      <span className="material-symbols-outlined ml-2">
                        document_scanner
                      </span>
                    </button>
                    <button onClick={() => copyContentToClipboard(result.contenu)} className="flex items-center">
                      Copier le contenu
                      <span className="material-symbols-outlined ml-2">
                        content_copy
                      </span>
                    </button>
                  </div>
                  <hr/>
                </div>
              ))}
            </div>
          </div>
        )} */}
      </div>

      <div className="flex items-center">
        {user && (
          <>
            <button 
              onClick={onSignOut} 
              className="bg-white text-navy px-4 py-2 rounded font-roboto hover:bg-silver transition-colors duration-300"
            >
              Log-out
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default TopBar;
